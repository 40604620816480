import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import qs from 'querystring';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';
import styles from './order.module.scss';

export function Head() {
  return (
    <>
      <SEO title="Оплата еще не пришла" description="Во время оплаты заказа произошла ошибка" />;
      <YandexTableau />
    </>
  );
}

function PaymentPendingPage() {
  const [retryUrl, setRetryUrl] = useState({});

  useEffect(() => {
    const query = typeof window !== 'undefined' ? window.location.search.replace('?', '') : null;
    if (query) {
      const result = qs.parse(query);
      if (result.retryUrl) {
        setRetryUrl(result.retryUrl);
      }
    }
  }, []);

  return (
    <Layout>
      <OneColumn>
        <div className={styles.block}>
          <h1 className={cn({ [styles.header]: true, [styles.pending]: true })}>
            Заказ еще не оплачен
          </h1>
          <p className={styles.description}>
            Если вы оплатили заказ, обратите внимание, что мы еще не получили информацию об успешной
            оплате.
            <br />
            <br />
            {retryUrl ? (
              <>
                Попробуйте&nbsp;
                <a className={styles.link} href={`${retryUrl}`}>
                  проверить статус заказа
                </a>
                &nbsp;еще раз&nbsp; или&nbsp;
                <Link className={styles.link} to="/contacts">
                  свяжитесь с нами
                </Link>
                &nbsp;любым удобным способом.
              </>
            ) : (
              <>
                Пожалуйста,&nbsp;
                <Link className={styles.link} to="/contacts">
                  свяжитесь с нами
                </Link>
                &nbsp;любым удобным способом.
              </>
            )}
          </p>
        </div>
      </OneColumn>
    </Layout>
  );
}

export default PaymentPendingPage;
